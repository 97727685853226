import React from "react"

import { motion } from 'framer-motion'
import AnimationFadeIn from "../animations/animation-fade-in";
import LogoSmallBlack from '../components/images/logo-small-black'
import LogoMobileSmallBlack from '../components/images/logo-mobile-small-black'
import Layout from "../components/layout"
import Seo from "../components/seo"
import WorkSlider from '../components/work-slider'

const Work = () => (

    <Layout>
        <Seo title="work" description="Wir haben schon für viele Themen Onlineshops entwickelt. Mode, Sport, Merchandise &mdash; aber auch der Bereich Lebensmittel ist in unseren Referenzen."/>
        <h1 className="subpage--headline">work</h1>
        <LogoSmallBlack title="ps:dreizehn GmbH"  />
        <LogoMobileSmallBlack title="ps:dreizehn GmbH"  />
        <motion.div variants={AnimationFadeIn} transition="spring" initial="hidden" animate="visible" exit="exit">
            <div className="content-wrapper">
                <div className="content">
                    <WorkSlider />
                </div>
            </div>
        </motion.div>
    </Layout>

)

export default Work