import React, { Component }  from "react";
import Slider from "react-slick";

import RefImg from "../components/images/image-referenzen"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'

const PrevArrow = function(props) {
    return (
        <div onClick={props.onClick} onKeyPress={props.onClick} className="slick--custom-arrow-prev" style={props.customStyle} role="presentation">
            <FontAwesomeIcon icon={faArrowLeft} />
        </div>
    );
};

const NextArrow = function(props) {
    return (
        <div onClick={props.onClick} onKeyPress={props.onClick} className="slick--custom-arrow-next" style={props.customStyle} role="presentation">
            <FontAwesomeIcon icon={faArrowRight} />
        </div>
    );
};

const Reference = props => (
    <div className="reference-item">
        <a href={props.link} title={props.title} target="_blank" rel="noopener noreferrer">
            <RefImg src={props.image} alt={props.title} />
            <div className="reference-name">
                {props.title}
            </div>
        </a>
    </div>
)

export default class WorkSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            prevArrow: <PrevArrow />,
            nextArrow: <NextArrow />,
            currentSlide: 1
        };
    }
    render() {
        let settings = {
            rows: 2,
            slidesPerRow: 3,
            infinite: true,
            prevArrow: this.state.prevArrow,
            nextArrow: this.state.nextArrow,
            responsive: [
                {
                    breakpoint: 1300,
                    settings: {
                        slidesPerRow: 2,
                    }
                },
                {
                    breakpoint: 650,
                    settings: {
                        slidesPerRow: 1,
                    }
                },
            ]
        };
        return (
            <Slider {...settings}>
                <div>
                    <Reference
                        title="Naturata Onlineshop"
                        link="https://www.naturata-shop.de/"
                        technology="Adobe XD, Shopware"
                        image="referenzen/referenzen-naturata.png"
                    />
                </div>
                <div>
                    <Reference
                        title="Almawin & Klar Onlineshop"
                        link="https://shop.almawin.de/"
                        technology="Adobe XD, Shopware"
                        image="referenzen/referenzen-almawin.png"
                    />
                </div>
                <div>
                    <Reference
                        title="Cotton Candy Onlineshop"
                        link="https://www.cottoncandy-fashionshop.de"
                        technology="Adobe XD, Shopware"
                        image="referenzen/referenzen-cottoncandy-os-2022.png"
                    />
                </div>
                <div>
                    <Reference
                        title="Allgäuer Büble Onlineshop"
                        link="https://www.bueble-shop.de"
                        technology="Sketch, Shopware"
                        image="referenzen/referenzen-bueble-os-2020.png"
                    />
                </div>
                <div>
                    <Reference
                        title="Berliner Pilsner Onlineshop"
                        link="https://www.berliner-pilsner-shop.de"
                        technology="Sketch, Shopware"
                        image="referenzen/referenzen-berlinerpilsner-os-2019.png"
                    />
                </div>
                <div>
                    <Reference
                        title="Vita Cola Onlineshop"
                        link="https://vitacola.finzelundschuck.de"
                        technology="Sketch, Shopware"
                        image="referenzen/referenzen-vitacola-os-2019.png"
                    />
                </div>
                <div>
                    <Reference
                        title="Sternburg Onlineshop"
                        link="https://www.sternburg-shop.de"
                        technology="Shopware"
                        image="referenzen/referenzen-sternburg-os-2018.png"
                    />
                </div>
                <div>
                    <Reference
                        title="Radio PSR Onlineshop"
                        link="https://shop.radiopsr.de"
                        technology="Shopware"
                        image="referenzen/referenzen-radiopsr-os-2018.png"
                    />
                </div>
                <div>
                    <Reference
                        title="Radeberger Onlineshop"
                        link="https://www.radeberger-shop.de"
                        technology="Shopware"
                        image="referenzen/referenzen-radeberger-os-2018.png"
                    />
                </div>
            </Slider>
        );
    }
}